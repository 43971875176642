import React from "react";
import { observer, inject } from "mobx-react";
import { Icon, Row, Col, Button, Message, Checkbox } from "@ucloud-fe/react-components";
import Settings from "../components/settings";
import Testing from "../components/testing/index";
import bgImg from "../common/image/com.png";
import { version } from "../config/version";
import Querystringify from "querystringify";
import { isPC, isSafari } from "../util/index";
import { LoginWrapper } from './home.styles.jsx';
import SDK from "urtc-sdk";
import "./login.css";
const { isSupportWebRTC, getDevices } = SDK;


@inject("store")
@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    let storeSettings = this.props.store.settings;
    const query = Querystringify.parse(props.location.search);
    console.log("query", query, storeSettings);
    const { roomId = "", roomType = "rtc", onlyAudio = false, userId, userName, token = '',logId = 0 } = query;
    storeSettings.setParamKey("onlyAudio", onlyAudio === "true");
    if (roomId) {
      storeSettings.joinRoom({
        roomId: roomId,
      });
    }
    if(!!userId){
      storeSettings.setParamKey('userId', userId)
    }
    if(!!userName){
      storeSettings.setParamKey('userName', userName)
    }
    if(!!token){
      storeSettings.setParamKey('phpToken', token)
    }
    if(!!logId){
      storeSettings.setParamKey('logId', logId)
    }
    if (roomType === "live") {
      storeSettings.setParamKey("roomType", "live");
      storeSettings.setParamKey("userRole", "pull");
    }

    //不支持rtc，统一权限改为拉流pull
    if (!isSupportWebRTC()) {
      storeSettings.setParamKey("userRole", "pull");
    }
    this.state = {
      loading: false,
      setVisible: true,
      roomIdValue: roomId,
      testingVisible: false,
      isPhone: !isPC(),
      supportRTC: isSupportWebRTC(),
      onlyAudio: onlyAudio === "true",
    };
	// setTimeout(this.joinIn(),500)
  }

  componentDidMount() {
    if (!this.state.supportRTC) {
      Message.error("当前浏览器不支持RTC推流，建议更换Safari 或 Chrome 重试");
    } else {
      getDevices((MediaDeviceInfos) => {
        //console.log("解决授权问题", MediaDeviceInfos)
      })
    }
  }

  joinIn = () => {
    let roomId = this.state.roomIdValue;
    let storeSettings = this.props.store.settings;
    console.log("storeSettings,", storeSettings);
    let userId = storeSettings.userId;
    this.props.history.push({
      pathname: "/class",
      state: { roomId: roomId, userId: userId, logId: storeSettings.logId  },
    });
    storeSettings.joinRoom({
      roomId: roomId,
      userId: userId,
      logId: storeSettings.logId
    });
  };

  setting = () => {
    this.setState({
      setVisible: true,
    });
    let storeSettings = this.props.store.settings;
    storeSettings.settingsActive(true);
  };

  roomId = (e) => {
    this.setState({
      roomIdValue: e.target.value,
    });
    console.log(e.target.value);
  };

  setClose() {
    this.setState({
      setVisible: false,

    });
  }

  toggleTesting = (flag) => {
    this.setState({
      testingVisible: flag,
    });
  };

  changeOnlyAudio = (e) => {
    this.props.store.settings.setParamKey("onlyAudio", e);
    this.setState({
      onlyAudio: e
    })
  }

  render() {
    const { setVisible, testingVisible, roomIdValue, isPhone, onlyAudio } = this.state;
    console.log("isSupportWebRTC", isSupportWebRTC());
    return (
      <LoginWrapper
        className="login"
        width={isPhone ? 300 : 512}
        isPhone={isPhone}
      >
        <div className="setting-content">
          <div className="header">
            <div className="header_left">
              <div className="header_Icon">
                
                  <img src={bgImg} alt="" />
              </div>
              <div className="header_right">
                <Icon onClick={this.setting} type={"cog"} />
                <Settings visible={setVisible} />
              </div>
            </div>
          </div>

          <div className="main">
            <Row getter={0}>
              <Col span={12}>
                <p className="title-left">Click to join the room</p>
              </Col>
            </Row>
            <div className="hide">
              <input
				disabled="disabled"
                value={roomIdValue}
                type="text"
                className="room-id"
                onChange={this.roomId}
              />
            </div>
            
            <div className="btn-content">
              <Button
                className="btn"
                styleType="primary"
                size="lg"
                onClick={this.joinIn}
              >
                {"Join in"}
              </Button>
            </div>
          </div>
          <div className="version-wrapper">
            <span>{`URTC : ${version}`}</span>
          </div>
        </div>
      </LoginWrapper>
    );
  }
}

export default Login;
